.banner__image img {
  width: 100%;
}

.banner__content--titiles h1{
  font-size: 40px;
}

.banner__content--titiles h1,
.banner__content--titiles h2{
  line-height: 1.2;
}


.banner__content--sup-title{
  font-size: 20px;
}

/* Tablet */

@media (min-width: 768px){
  .banner__image{
    width: 50%;
    flex: 0 0 auto;
  }

  .banner__content{
    text-align: right;
  }

  .banner__content.section__padding{
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .banner__content--sup-title{
    font-size: 25px;
  }

  .banner__content--titiles h1{
    font-size: 44px;
  }
}

@media (min-width: 1024px){
  .banner__content--sup-title{
    font-size: 34px;
  }
  .banner__content--titiles h1{
    font-size: 52px;
  }
}


/* lx screen */

@media (min-width: 1280px){

  .banner__content.section__padding{
    padding-right: 5rem;
  }

  .banner__image {
    width: 50%;
  }

  .banner__content--sup-title{
    font-size: 40px;
  }

  .banner__content--titiles h1{
    font-size: 90px;
    line-height: 0.8;
  }
}


@media (min-width: 1536px){
  .banner__content--sup-title{
    font-size: 55px;
  }


  .banner__content--titiles h1{
    font-size: 90px;
  }
}