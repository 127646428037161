.review__box{
  border: 1px solid var(--color-btn-bg);
  padding: 1.5rem;
}

.review__box--top{
  display: flex;
}

.review__box--star + .review__box--star{
  margin-left: 0.25rem;
}

.review__box--bottom{
  margin-top: 1rem;
}



@media (min-width: 768px){
  .review__box{
    padding: 2rem;
  }
}


@media (min-width: 1024px){
  .review__box{
    padding: 3rem;
  }

  .review__box--star + .review__box--star{
    margin-left: 0.5rem;
  }
}



@media (min-width: 1280px){
  .review__box--star + .review__box--star{
    margin-left: 0.75rem;
  }
}