.page-banner{
  position: relative;
  overflow: hidden;
}

.page-banner img{
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 0;
}

.page-banner::before{
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--color-quaternary);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0.6;
}

.page-banner__text{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 140px;

  position: relative;
  z-index: 1;

  color: var(--color-text-light);
  letter-spacing: 0.2rem;
  text-align: center;
}